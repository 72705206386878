const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || class {
  constructor() {}
  get isShim() {
    return true;
  }
  addEventListener() {}
  start() {}
  stop() {}
};

const Call = new class {
  constructor() {
    this.state = {
      ready: new Promise(r => this.setReady = r),
      isListening: false,
      character: null,
      lastPhrase: {
        transcript: '',
        timeStamp: 0,
      },
      phraseCallback: null,
    };

    const rec = this.recognizer = new SpeechRecognition();
    rec.interimResults = true;
    rec.continuous = true;
    rec.maxAlternatives = 3;

    rec.addEventListener('start', () => {
      this.state.isListening = true;
      this.setReady();
    });
    rec.addEventListener('result', e => {
      const result = e.results[e.resultIndex];
      if (this.state.character?.isSaying || !result.isFinal) {
        return;
      }
      const [first] = result;
      if (!first.transcript || !first.confidence) {
        // console.group(`You don't say...`);
        // console.log(result);
        // console.groupEnd();
        return;
      }
      const scattershot = [...result].map(x => x.transcript).join(' | ');
      console.group(`You say:`);
      console.log(first.transcript);
      console.log(result);
      console.log(scattershot);
      console.groupEnd();
      if (!this.state.phraseCallback) {
        return;
      }
      this.state.phraseCallback(scattershot);
    });
    rec.addEventListener('error', e => {
      console.log(e);
    });
    rec.addEventListener('end', () => {
      this.state.isListening = false;
      this.state.ready = new Promise(r => this.setReady = r);
    });
  }

  setCallback(callback) {
    this.state.phraseCallback = callback;
  }

  toggle() {
    if (this.state.isListening) {
      this.stop();
      return;
    }
    this.start();
  }

  start(character) {
    this.state.character = character;
    if (!this.state.isListening) {
      this.recognizer.start();
    }
    return this.state.ready;
  }

  resume() {
    if (this.state.isListening) {
      return;
    }
    this.state.ready = new Promise(r => this.setReady = r);
    this.recognizer.start();
  }

  stop() {
    this.recognizer.stop();
    this.state.character?.shutUp();
  }
}();

window.Call = Call;

export default Call;
