import { sleep } from "./utils";

export default class Sound {
  constructor(url) {
    const audio = this.audio = new Audio(`/sounds/${url}.mp3`);
    this.duration = 0;
    audio.addEventListener('loadeddata', () => this.duration = audio.duration * 1e3);
    this.ready = new Promise(r => audio.addEventListener('canplaythrough', r));
  }

  play(options = {}) {
    return new Promise(async resolve => {
      await this.ready;
      this.audio.currentTime = 0;
      this.audio.loop = Boolean(options.loop);
      await this.audio.play();
      await sleep(this.duration + 50);
      resolve();
    });
  }

  pause() {
    this.audio.pause();
  }
}
