import { useEffect, useState } from 'react';
import './App.css';
import CHARACTERS from './character';
import * as Screens from './Screens';
import Sound from './sound';
import Call from './speech';
import { sleep } from './utils';

let [character] = CHARACTERS;
if (window.location.hash && +window.location.hash[1]) {
  character = CHARACTERS[+window.location.hash[1]] || character;
}

const ringtone = new Sound('ringtone');

function App() {
  const handlePhrase = async phrase => {
    const reply = character.replyTo(phrase);
    await character.say(reply);
    if (reply.disconnect) {
      handleCall();
    } else {
      Call.resume();
    }
  };

  //
  // Game State
  //

  const handleTitle = async () => {
    setState({
      id: 'Waiting',
      callback: handleWaiting,
    });
    await Promise.all([sleep(3000), character.prepare(), ringtone.ready]);
    if (!document.querySelector('.WaitingScreen')) {
      return;
    }
    ringtone.play({ loop: true });
    setState({
      id: 'Incoming',
      callback: handleIncoming,
    });
  };

  const handleWaiting = () => {
    setState({
      id: 'Title',
      callback: handleTitle,
    });
  };
  
  const handleIncoming = async () => {
    ringtone.pause();
    setSeconds(0);
    setState({
      id: 'Call',
      callback: handleCall,
    });
    Call.setCallback(handlePhrase);
    await Call.start(character);
    Call.recognizer.stop();
    await character.say('intro');
    Call.resume();
  };

  const handleCall = () => {
    Call.setCallback(null);
    Call.stop();
    setResults(character.summary);
    character = CHARACTERS[(CHARACTERS.indexOf(character) + 1) % CHARACTERS.length];
    setState({
      id: 'Results',
      callback: handleTitle,
    });
  };
  
  const [state, setState] = useState({
    id: 'Title',
    callback: handleTitle,
  });
  
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    if (state.id !== 'Call') {
      return;
    }
    const timeout = setTimeout(() => setSeconds(seconds + 1), 1e3);
    return () => clearTimeout(timeout);
  }, [state, seconds]);

  const [results, setResults] = useState('');

  const Main = Screens[state.id];

  return (
    <div className='App'>
      <Main callback={state.callback} seconds={seconds} results={results} />
    </div>
  );
}

export default App;
