import { useEffect } from 'react';
import './Screens.css';

const renderTime = seconds => (
  <time>{
    Math.floor(seconds / 60)
  }<span className='colon'>:</span>{
    (seconds % 60).toString().padStart(2, '0')
  }</time>
);

export function Title(props) {
  return (
    <main className='TitleScreen'>
      <header>Welcome Back</header>
      <p>If you're ready to start helping callers with anything and everything again...</p>
      <button className='clockIn' onClick={props.callback}>Clock In</button>
    </main>
  );
}

export function Waiting(props) {
  return (
    <main className='WaitingScreen'>
      <header>Waiting for Call</header>
      <p>Someone should ring you any minute now...</p>
      <button className='clockOut' onClick={props.callback}>Clock Out</button>
    </main>
  );
}

export function Incoming(props) {
  useEffect(() => {
    if (navigator.vibrate) {
      const interval = setInterval(() => navigator.vibrate(500), 1485);
      return () => {
        clearInterval(interval);
        navigator.vibrate(0);
      };
    }
  }, []);

  return (
    <main className='IncomingScreen'>
      <header>Incoming Call</header>
      <button className='circle answer material-icons' onClick={props.callback}>call</button>
    </main>
  );
}

export function Call(props) {
  return (
    <main className='CallScreen'>
      {renderTime(props.seconds)}
      <button
        className='circle hangup material-icons'
        onClick={props.callback}
      >
        call_end
      </button>
    </main>
  );
}

export function Results(props) {
  return (
    <main className='ResultsScreen'>
      {renderTime(props.seconds)}
      <p>{props.results}</p>
      <button className='saveReport' onClick={props.callback}>Save Report</button>
    </main>
  );
}
