import Signals from "./signals";
import Sound from "./sound";

export class Character {
  constructor(json = {}) {
    this.name = json.name;
    this.initialState = {
      heardName: false,
      previousPhrase: null, // Only actual phrases
      previousReply: null, // Actual phrases or error handlers (e.g. "what")
      ...json.initialState,
    };
    this.state = null;
    this.sounds = null;
    this.isSaying = null;
    this.ready = null;
    this.signals = json.signals || {};
    this.oppositeSignals = json.oppositeSignals || [];
    if (typeof this.oppositeSignals[0] === 'string') {
      this.oppositeSignals = [this.oppositeSignals];
    }
    this.phrases = Object.fromEntries(
      Object.entries({
        disconnect: { disconnect: true },
        ...json.phrases,
      })
      .map(([key, phrase]) => {
        phrase.key = key;
        if (!phrase.maxUses) {
          switch (key) {
            case 'intro': phrase.maxUses = 0; break;
            case 'what': phrase.maxUses = 3; break;
            default: phrase.maxUses = 1; break;
          }
        }
        if (phrase.then) {
          phrase.then = phrase.then.bind(this);
        }
        return [key, phrase];
      })
    );
    this.onReply = json.onReply || null;
  }

  get summary() {
    const name = this.state.heardName ? this.name : 'the caller';
    const phrase = this.phrases[this.state.previousReply];
    if (typeof phrase?.disconnect === 'function') {
      return phrase.disconnect(name);
    }
    return `You rudely hung up on ${name} before helping them with anything.`;
  }

  reset() {
    this.state = { ...this.initialState };
    for (const phrase of Object.values(this.phrases)) {
      phrase.uses = 0;
      phrase.usable = true;
    }
  }

  prepare() {
    this.reset();
    this.sounds = Object.fromEntries(Object.values(this.phrases)
      .map(x => x.sound ? [x.key, new Sound(`${this.name.toLowerCase()}/${x.sound}`)] : null)
      .filter(x => x)
    );
    return this.ready = Promise.all(Object.values(this.sounds));
  }

  canUse(phrase) {
    return this.phrases[phrase]?.usable || false;
  }

  disablePhrase(phrase) {
    if (phrase in this.phrases) {
      this.phrases[phrase].usable = false;
    }
  }

  signalsFrom(phrase) {
    return Signals.from(phrase, {
      signals: this.signals,
      opposites: this.oppositeSignals,
    });
  }

  getReplyPhrase(phrase) {
    const signals = this.signalsFrom(phrase);
    console.log(signals);
    if (this.state.previousPhrase) {
      const previous = this.phrases[this.state.previousPhrase];
      const response = previous.onReply && previous.onReply(signals, phrase);
      if (response) {
        return response;
      }
    }
    if (this.onReply) {
      const response = this.onReply(signals, phrase);
      if (response) {
        return response;
      }
    }
    if (signals.name && this.canUse('name')) {
      return 'name';
    }
    if (signals.hello && this.canUse('hello')) {
      return 'hello';
    }
    if (this.state.previousReply !== 'what' && this.canUse('what')) {
      return 'what';
    }
    return 'disconnect';
  }

  replyTo(phrase) {
    const response = this.phrases[this.getReplyPhrase(phrase)];
    this.state.previousReply = response.key;
    if (!['what', 'disconnect'].includes(response.key)) {
      this.state.previousPhrase = response.key;
    }
    if (++ response.uses == response.maxUses) {
      response.usable = false;
    }
    if (response.key === 'name') {
      this.state.heardName = true;
    }
    return response;
  }

  async say(phrase) {
    console.group(`${this.name} says:`);
    console.log(phrase);
    console.groupEnd();
    if (typeof phrase === 'object') {
      phrase = phrase.key;
    }
    if (!this.state.previousPhrase) {
      this.state.previousPhrase = phrase;
      this.state.previousReply = phrase;
    }
    const wasSaying = this.isSaying = this.sounds[phrase] ?? null;
    await this.isSaying?.play();
    if (this.isSaying !== wasSaying) {
      return;
    }
    this.isSaying = null;
  }

  shutUp() {
    if (!this.isSaying) {
      return;
    }
    this.isSaying.pause();
    this.isSaying = null;
  }
}

const CHARACTERS = [
  {
    name: 'Steven',
    signals: {
      phone: [
        'phone',
        'not everything',
        'not anything',
      ],
      anything: [
        'not just phones',
        'not only phones',
        'not phones',
        'everything',
        'anything',
      ],
    },
    oppositeSignals: ['phone', 'anything'],
    phrases: {
      intro: {
        sound: 'intro',
      },
      hello: {
        sound: 'hello',
      },
      name: {
        sound: 'name',
      },
      aboutMyQuestion: {
        sound: 'aboutMyQuestion',
        onReply: function(signals) {
          if (signals.yes || signals.phone) {
            return 'makesSense';
          } else if (signals.no || signals.anything) {
            return 'withAnything';
          } else {
            return 'disconnect';
          }
        },
      },
      withAnything: {
        sound: 'withAnything',
        disconnect: name => `Great job! You didn't waste time helping ${name}. I'm not totally sure how that helps our organization, but doesn't it feel nice?`,
      },
      makesSense: {
        sound: 'makesSense',
        disconnect: name => `Okay, so to be clear. You did lie to ${name}. You are supposed to help people with literally anything. Also now they might call back about something lame, like their email. Ew.`,
      },
      what: {
        sound: 'what',
      },
      disconnect: {
        sound: 'disconnect',
        disconnect: name => `Hurray! You helped ${name} feel unintelligent! In hindsight, there may have been a better option.`,
      },
    },
    onReply: function(signals) {
      if (signals.yes || signals.phone) {
        return 'makesSense';
      } else if (signals.no || signals.anything) {
        return 'withAnything';
      } else if (signals.name && this.canUse('name')) {
        return 'name';
      } else if (signals.hello && this.canUse('hello')) {
        return 'hello';
      } else if (this.canUse('aboutMyQuestion')) {
        return 'aboutMyQuestion';
      }
    }
  },
  {
    name: 'Norman',
    signals: {
      time: [
        'time',
        'timer',
        'clock',
        'countdown',
        'remaining',
      ],
      wires: [
        'wires',
        'cable',
        'cut the',
        'see anything',
      ],
      red: [
        'red',
        'red one',
        'red wire',
      ],
      otherColor: [
        'blue',
        'green',
        'orange',
        'yellow',
        'pink',
        'magenta',
        'purple',
        'white',
        'black',
      ],
    },
    oppositeSignals: ['red', 'otherColor'],
    phrases: {
      intro: {
        sound: 'intro',
      },
      hello: {
        sound: 'hello',
      },
      name: {
        sound: 'name',
      },
      time: {
        sound: 'time',
      },
      aboutMyQuestion: {
        sound: 'aboutMyQuestion',
      },
      wires: {
        sound: 'wires',
        onReply: function(signals) {
          if (signals.red) {
            return 'redWire';
          } else if (signals.otherColor) {
            return 'otherWire';
          }
        },
      },
      redWire: {
        sound: 'redWire',
        disconnect: name => `You saved ${name}'s life! I can't believe it really was just the red wire.`,
      },
      otherWire: {
        sound: 'otherWire',
        disconnect: name => `So... That wasn't great. But hey, ${name} probably would've blown up no matter which wire you chose.`,
      },
      what: {
        sound: 'what',
      },
      disconnect: {
        sound: 'disconnect',
        disconnect: name => `So... That wasn't great. But hey, ${name} probably was probably going to die no matter who they called. Unless they called someone who actually knew about bombs.`,
      },
    },
    onReply: function (signals) {
      if (signals.name && this.canUse('name')) {
        return 'name';
      } else if (signals.time && this.canUse('time')) {
        return 'time';
      } else if (signals.wires && this.canUse('wires')) {
        if (signals.red) {
          return 'redWire';
        } else if (signals.otherColor) {
          return 'otherWire';
        }
        return 'wires';
      } else if (this.canUse('aboutMyQuestion')) {
        return 'aboutMyQuestion';
      }
    },
  },
].map(x => new Character(x));

/// TEMP: Testing!!!
window.c = CHARACTERS;

export default CHARACTERS;