export class Stems {
  constructor(original, values) {
    this.original = original;
    this.values = values;
    this.converted = this.values.join(' ');
  }

  static from(input) {
    if (input instanceof this) {
      return input;
    }
    return new this(input, input
      .toLowerCase()
      .replace(/\bwon't\b/g, 'will not')
      .replace(/\wn't\b/g, ' not')
      .replace(/\w'm\b/g, ' am')
      .replace(/\w've\b/g, ' have')
      .replace(/\w'll\b/g, ' will')
      .replace(/\w's\b/g, ' is')
      .replace(/\w're\b/g, ' are')
      .replace(/\byeah\b/g, 'yes')
      .replace(/\b(?:sh|c|w)ould\s/g, ' ')
      .replace(/\bwith\b/g, '')
      .replace(/\W/g, ' ')
      .split(' ')
      .map(x => x
        .replace(/(\w)\1+/g, '$1')
        .replace(/^p?re(\w{2})/, '$1')
        .replace(/aid$/, 'ay')
        .replace(/(?:ies?|ier|iest|iness|ily)$/, 'y')
        .replace(/e?s$/, '')
        .replace(/(?:ing|ly|ed|ish)$/, '')
        .replace(/\By$/, '')
        .replace(/(\w{2})e$/, '$1')
      )
      .filter(x => x)
    );
  }

  contains(input) {
    return this.converted.includes(this.constructor.from(input).converted);
  }

  containsAny(inputs) {
    return inputs.some(x => this.contains(x));
  }

  indexes(inputs) {
    const needles = inputs.map(x => this.constructor.from(x));
    const result = [];
    for (let i = this.values.length; i --; ) {
      needles: for (const needle of needles) {
        for (let j = needle.values.length; j --; ) {
          if (needle.values[j] !== this.values[i + j]) {
            continue needles;
          }
        }
        result.unshift(i);
      }
    }
    return result;
  }

  lastIndex(inputs) {
    return this.indexes(inputs).at(-1);
  }
  
  count(inputs) {
    return this.indexes(inputs).length;
  }
}

const SIGNALS_MAP = {
  hello: [
    'hello',
    'hi',
    'morning',
    'afternoon',
    'evening',
  ],
  yes: [
    'yes',
    'sure',
    'okay',
    'absolutely',
    'that works',
    'right',
    'correct',
  ],
  no: [
    'know',
    'no',
    'does not work',
    'wrong',
    'incorrect',
  ],
  name: [
    'name',
    'who are you',
    'what are you called',
  ],
};

export default class Signals {
  constructor(json = {}) {
    Object.assign(this, json);
  }

  static from(input, options = {}) {
    const signalsMap = { ...SIGNALS_MAP, ...(options.signals || {}) };
    const stems = Stems.from(input);
    const counts = {};

    for (const [key, inputs] of Object.entries(signalsMap)) {
      counts[key] = stems.count(inputs);
    }

    // If opposites were said, determine which was said last
    for (const opposites of [['yes', 'no'], ...(options.opposites || [])]) {
      let lastIndex = -1, lastKey = null;
      for (const key of opposites) {
        const index = stems.lastIndex(signalsMap[key]);
        if (index > lastIndex) {
          lastIndex = index;
          lastKey = key;
        }
      }
      if (lastKey) {
        for (const key of opposites) {
          if (key === lastKey) {
            continue;
          }
          counts[key] = 0;
        }
      }
    }

    const scale = Object.values(counts).reduce((c, i) => Math.max(c, i), 1);
    return new this(Object.fromEntries(Object.entries(counts).map(
      ([key, count]) => [key, count / scale > 0.34]
    )));
  }
}

window.Stems = Stems;
window.Signals = Signals;
